import { NotificationService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CurrentUser, ExchangeRate, SearchGQL } from 'src/app/graphql/user-service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

import { LotsFilter, LotsFilterComponent } from '../lots-filter/lots-filter.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  category: number | null = null;
  search: string | null = null;

  items: any = null;
  currentUser: CurrentUser = null;
  exchangeRate: ExchangeRate = null;
  currentTime = null;

  loading = false;

  filter: LotsFilter = null;

  subscription: Subscription = null;

  page = 1;
  total = 0;
  pageSize = 20;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private translate: TranslateService,
    private searchService: SearchService,
    private notificationService: NotificationService,
    private searchGQL: SearchGQL,
  ) {}

  ngOnInit(): void {
    this.searchService.currentService.next(SearchServiceEnum.Yahoo);
    this.route.queryParams.subscribe((params) => {
      this.page = Number(params.page) || 1;
      this.pageSize = Number(params.pageSize) || this.pageSize;
      if (params.category) {
        this.category = Number(params.category);
        this.searchService.currentYahooCategory.next(params.category);
      }
      this.search = params.q || null;

      this.filter = LotsFilterComponent.getFilterFromParams(params);

      this.unsub();
      this.fetchSearch();
    });
  }

  fetchSearch(): void {
    this.loading = true;
    this.subscription = this.searchGQL
      .watch({
        category: this.category,
        search: this.search,
        pageSize: this.pageSize,
        offset: (this.page - 1) * this.pageSize || 1,
        ...this.filter,
      })
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Search response', result);

          if (result && result.data && result.data.auctions) {
            this.items = result.data.auctions.search.ResultSet.Result.Item;
            this.exchangeRate = result.data.exchangeRate;
            this.currentUser = result.data.currentUser as unknown as CurrentUser;
            this.currentTime = result.data.currentTime;

            this.total = Number(
              result.data.auctions.search.ResultSet['@attributes']?.totalResultsAvailable,
            );

            this.title.setTitle(this.translate.instant('SEARCH.SEARCH'));
          } else {
            this.notificationService.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  unsub(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsub();
  }
}
