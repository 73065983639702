import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
  Validation = 'VALIDATION'
}

export type Article = {
  __typename?: 'Article';
  isRead: Scalars['Boolean'];
  id: Scalars['Long'];
  siteId: Scalars['Long'];
  title?: Maybe<TranslatedContent>;
  content?: Maybe<TranslatedContent>;
  page?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewsCount?: Maybe<Scalars['Long']>;
  categories: Array<Category>;
  site: Site;
};

export type ArticleFilterInput = {
  and?: Maybe<Array<ArticleFilterInput>>;
  or?: Maybe<Array<ArticleFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  title?: Maybe<TranslatedContentFilterInput>;
  content?: Maybe<TranslatedContentFilterInput>;
  page?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  publishedAt?: Maybe<DateTimeOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  viewsCount?: Maybe<LongOperationFilterInput>;
  categories?: Maybe<ListFilterInputTypeOfCategoryFilterInput>;
  site?: Maybe<SiteFilterInput>;
};

export type ArticleSortInput = {
  id?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  title?: Maybe<TranslatedContentSortInput>;
  content?: Maybe<TranslatedContentSortInput>;
  page?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  publishedAt?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  viewsCount?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
};

/** A segment of a collection. */
export type ArticlesCollectionSegment = {
  __typename?: 'ArticlesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Article>>;
  totalCount: Scalars['Int'];
};

export type Block = {
  __typename?: 'Block';
  id: Scalars['Long'];
  siteId: Scalars['Long'];
  page?: Maybe<Scalars['String']>;
  content?: Maybe<TranslatedContent>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  site: Site;
};

export type BlockFilterInput = {
  and?: Maybe<Array<BlockFilterInput>>;
  or?: Maybe<Array<BlockFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  page?: Maybe<StringOperationFilterInput>;
  content?: Maybe<TranslatedContentFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  site?: Maybe<SiteFilterInput>;
};

export type BlockSortInput = {
  id?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  page?: Maybe<SortEnumType>;
  content?: Maybe<TranslatedContentSortInput>;
  createdAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
};

/** A segment of a collection. */
export type BlocksCollectionSegment = {
  __typename?: 'BlocksCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Block>>;
  totalCount: Scalars['Int'];
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Long'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<TranslatedContent>;
  parentId?: Maybe<Scalars['Long']>;
  country?: Maybe<Scalars['String']>;
  siteId: Scalars['Long'];
  weight?: Maybe<Scalars['Long']>;
  articles: Array<Article>;
  site: Site;
  parent?: Maybe<Category>;
  children: Array<Category>;
};

export type CategoryFilterInput = {
  and?: Maybe<Array<CategoryFilterInput>>;
  or?: Maybe<Array<CategoryFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  title?: Maybe<TranslatedContentFilterInput>;
  parentId?: Maybe<LongOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  weight?: Maybe<LongOperationFilterInput>;
  articles?: Maybe<ListFilterInputTypeOfArticleFilterInput>;
  site?: Maybe<SiteFilterInput>;
  parent?: Maybe<CategoryFilterInput>;
  children?: Maybe<ListFilterInputTypeOfCategoryFilterInput>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type DateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ListFilterInputTypeOfArticleFilterInput = {
  all?: Maybe<ArticleFilterInput>;
  none?: Maybe<ArticleFilterInput>;
  some?: Maybe<ArticleFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBlockFilterInput = {
  all?: Maybe<BlockFilterInput>;
  none?: Maybe<BlockFilterInput>;
  some?: Maybe<BlockFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCategoryFilterInput = {
  all?: Maybe<CategoryFilterInput>;
  none?: Maybe<CategoryFilterInput>;
  some?: Maybe<CategoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfNewsFilterInput = {
  all?: Maybe<NewsFilterInput>;
  none?: Maybe<NewsFilterInput>;
  some?: Maybe<NewsFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type LongOperationFilterInput = {
  eq?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Maybe<Scalars['Long']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Long']>>>;
  gt?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  nlt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  userArticleViewCreate: UserArticleViewCreatePayload;
  userNewsViewCreate: UserNewsViewCreatePayload;
  userNewsViewCreateAll: UserNewsViewCreateAllPayload;
  userArticleViewCreateAll: UserArticleViewCreateAllPayload;
};


export type MutationUserArticleViewCreateArgs = {
  input: UserArticleViewCreateInput;
};


export type MutationUserNewsViewCreateArgs = {
  input: UserNewsViewCreateInput;
};


export type MutationUserNewsViewCreateAllArgs = {
  input: UserNewsViewCreateAllInput;
};


export type MutationUserArticleViewCreateAllArgs = {
  input: UserArticleViewCreateAllInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  isRead: Scalars['Boolean'];
  id: Scalars['Long'];
  siteId: Scalars['Long'];
  title?: Maybe<TranslatedContent>;
  content?: Maybe<TranslatedContent>;
  country?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewsCount?: Maybe<Scalars['Long']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  site: Site;
};

/** A segment of a collection. */
export type NewsCollectionSegment = {
  __typename?: 'NewsCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<News>>;
  totalCount: Scalars['Int'];
};

export type NewsFilterInput = {
  and?: Maybe<Array<NewsFilterInput>>;
  or?: Maybe<Array<NewsFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  title?: Maybe<TranslatedContentFilterInput>;
  content?: Maybe<TranslatedContentFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  publishedAt?: Maybe<DateTimeOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  viewsCount?: Maybe<LongOperationFilterInput>;
  isImportant?: Maybe<BooleanOperationFilterInput>;
  image?: Maybe<StringOperationFilterInput>;
  site?: Maybe<SiteFilterInput>;
};

export type NewsSortInput = {
  id?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  title?: Maybe<TranslatedContentSortInput>;
  content?: Maybe<TranslatedContentSortInput>;
  country?: Maybe<SortEnumType>;
  publishedAt?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  viewsCount?: Maybe<SortEnumType>;
  isImportant?: Maybe<SortEnumType>;
  image?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
};

export type Query = {
  __typename?: 'Query';
  news?: Maybe<NewsCollectionSegment>;
  newsEntry?: Maybe<News>;
  articles?: Maybe<ArticlesCollectionSegment>;
  article?: Maybe<Article>;
  blocks?: Maybe<BlocksCollectionSegment>;
  block?: Maybe<Block>;
};


export type QueryNewsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
  where?: Maybe<NewsFilterInput>;
  order?: Maybe<Array<NewsSortInput>>;
};


export type QueryNewsEntryArgs = {
  id: Scalars['Long'];
};


export type QueryArticlesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
  where?: Maybe<ArticleFilterInput>;
  order?: Maybe<Array<ArticleSortInput>>;
};


export type QueryArticleArgs = {
  where?: Maybe<ArticleFilterInput>;
  order?: Maybe<Array<ArticleSortInput>>;
};


export type QueryBlocksArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockFilterInput>;
  order?: Maybe<Array<BlockSortInput>>;
};


export type QueryBlockArgs = {
  id?: Maybe<Scalars['Long']>;
  page?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['Long'];
  domain?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  articles: Array<Article>;
  blocks: Array<Block>;
  categories: Array<Category>;
  news: Array<News>;
};

export type SiteFilterInput = {
  and?: Maybe<Array<SiteFilterInput>>;
  or?: Maybe<Array<SiteFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  domain?: Maybe<StringOperationFilterInput>;
  title?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  articles?: Maybe<ListFilterInputTypeOfArticleFilterInput>;
  blocks?: Maybe<ListFilterInputTypeOfBlockFilterInput>;
  categories?: Maybe<ListFilterInputTypeOfCategoryFilterInput>;
  news?: Maybe<ListFilterInputTypeOfNewsFilterInput>;
};

export type SiteSortInput = {
  id?: Maybe<SortEnumType>;
  domain?: Maybe<SortEnumType>;
  title?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type TranslatedContent = {
  __typename?: 'TranslatedContent';
  ru?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  cn?: Maybe<Scalars['String']>;
  jp?: Maybe<Scalars['String']>;
  kr?: Maybe<Scalars['String']>;
};

export type TranslatedContentFilterInput = {
  and?: Maybe<Array<TranslatedContentFilterInput>>;
  or?: Maybe<Array<TranslatedContentFilterInput>>;
  ru?: Maybe<StringOperationFilterInput>;
  en?: Maybe<StringOperationFilterInput>;
  cn?: Maybe<StringOperationFilterInput>;
  jp?: Maybe<StringOperationFilterInput>;
  kr?: Maybe<StringOperationFilterInput>;
};

export type TranslatedContentSortInput = {
  ru?: Maybe<SortEnumType>;
  en?: Maybe<SortEnumType>;
  cn?: Maybe<SortEnumType>;
  jp?: Maybe<SortEnumType>;
  kr?: Maybe<SortEnumType>;
};

export type UserArticleViewCreateAllInput = {
  domain: Scalars['String'];
};

export type UserArticleViewCreateAllPayload = {
  __typename?: 'UserArticleViewCreateAllPayload';
  mutationError?: Maybe<MutationError>;
};

export type UserArticleViewCreateInput = {
  id: Scalars['Long'];
};

export type UserArticleViewCreatePayload = {
  __typename?: 'UserArticleViewCreatePayload';
  mutationError?: Maybe<MutationError>;
};

export type UserNewsViewCreateAllInput = {
  domain: Scalars['String'];
};

export type UserNewsViewCreateAllPayload = {
  __typename?: 'UserNewsViewCreateAllPayload';
  mutationError?: Maybe<MutationError>;
};

export type UserNewsViewCreateInput = {
  id: Scalars['Long'];
};

export type UserNewsViewCreatePayload = {
  __typename?: 'UserNewsViewCreatePayload';
  mutationError?: Maybe<MutationError>;
};

export type ArticleQueryVariables = Exact<{
  where?: Maybe<ArticleFilterInput>;
  order?: Maybe<Array<ArticleSortInput> | ArticleSortInput>;
}>;


export type ArticleQuery = { __typename?: 'Query', article?: Maybe<{ __typename?: 'Article', publishedAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }>, content?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }> };

export type ArticlesQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
  where?: Maybe<ArticleFilterInput>;
  order?: Maybe<Array<ArticleSortInput> | ArticleSortInput>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles?: Maybe<{ __typename?: 'ArticlesCollectionSegment', totalCount: number, items?: Maybe<Array<{ __typename?: 'Article', id: any, publishedAt?: Maybe<any>, page?: Maybe<string>, country?: Maybe<string>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }>> }> };

export type NewsEntryQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type NewsEntryQuery = { __typename?: 'Query', newsEntry?: Maybe<{ __typename?: 'News', id: any, country?: Maybe<string>, image?: Maybe<string>, publishedAt?: Maybe<any>, createdAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }>, content?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }> };

export type NewsQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
  where?: Maybe<NewsFilterInput>;
  order?: Maybe<Array<NewsSortInput> | NewsSortInput>;
}>;


export type NewsQuery = { __typename?: 'Query', news?: Maybe<{ __typename?: 'NewsCollectionSegment', totalCount: number, items?: Maybe<Array<{ __typename?: 'News', id: any, image?: Maybe<string>, publishedAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }>> }> };

export const ArticleDocument = gql`
    query article($where: ArticleFilterInput, $order: [ArticleSortInput!]) {
  article(where: $where, order: $order) {
    title {
      ru
      en
    }
    content {
      ru
      en
    }
    publishedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArticleGQL extends Apollo.Query<ArticleQuery, ArticleQueryVariables> {
    document = ArticleDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArticlesDocument = gql`
    query articles($take: Int, $skip: Int, $unread: Boolean, $where: ArticleFilterInput, $order: [ArticleSortInput!]) {
  articles(
    take: $take
    skip: $skip
    unread: $unread
    where: $where
    order: $order
  ) {
    items {
      id
      publishedAt
      page
      country
      title {
        ru
        en
      }
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArticlesGQL extends Apollo.Query<ArticlesQuery, ArticlesQueryVariables> {
    document = ArticlesDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsEntryDocument = gql`
    query newsEntry($id: Long!) {
  newsEntry(id: $id) {
    id
    title {
      ru
      en
    }
    country
    image
    publishedAt
    content {
      ru
      en
    }
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsEntryGQL extends Apollo.Query<NewsEntryQuery, NewsEntryQueryVariables> {
    document = NewsEntryDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsDocument = gql`
    query news($take: Int, $skip: Int, $unread: Boolean, $where: NewsFilterInput, $order: [NewsSortInput!]) {
  news(take: $take, skip: $skip, unread: $unread, where: $where, order: $order) {
    items {
      id
      title {
        ru
        en
      }
      image
      publishedAt
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsGQL extends Apollo.Query<NewsQuery, NewsQueryVariables> {
    document = NewsDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }