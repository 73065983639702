<section>
  <h3 nz-typography>{{ 'AUCTION.COST_CALC.COST_CALC' | translate }}</h3>
  <form nz-row nzAlign="bottom" nz-form nzLayout="vertical" nzGutter="10">
    <div *ngIf="!isAuth" nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label nzNoColon>
          {{ 'AUCTION.COST_CALC.YOU_BID' | translate }}
        </nz-form-label>
        <nz-form-control>
          <input
            nz-input
            [(ngModel)]="bid"
            name="bid"
            type="number"
            (ngModelChange)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="isAuth ? 8 : 12">
      <nz-form-item>
        <nz-form-label nzNoColon>
          {{ 'AUCTION.COST_CALC.WEIGHT' | translate }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            [(ngModel)]="selectedWeight"
            name="weight"
            [nzPlaceHolder]="'AUCTION.COST_CALC.WEIGHT_PLACEHOLDER' | translate"
            (ngModelChange)="calculate()"
            nzDropdownClassName="skiptranslate"
          >
            <nz-option *ngFor="let w of weight" [nzLabel]="w" [nzValue]="w"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="isAuth ? 8 : 12">
      <nz-form-item>
        <div
          *ngIf="suggestedJpDelivery"
          nz-tooltip
          [nzTooltipTitle]="'AUCTION.COST_CALC.SUGGESTED_HINT' | translate"
          (click)="jpDelivery = suggestedJpDelivery"
        >
          <span>{{ 'AUCTION.COST_CALC.JP_DELIVERY' | translate }}</span
          ><br />
          <span style="text-decoration: underline dotted; cursor: pointer">
            {{ 'AUCTION.COST_CALC.SUGGESTED' | translate }}
            <app-currency
              [value]="suggestedJpDelivery"
              [exchangeRate]="exchangeRate"
            ></app-currency>
          </span>
        </div>
        <nz-form-label nzNoColon *ngIf="!suggestedJpDelivery">
          {{ 'AUCTION.COST_CALC.JP_DELIVERY' | translate }}
        </nz-form-label>
        <nz-form-control>
          <input
            nz-input
            [(ngModel)]="jpDelivery"
            name="jpDelivery"
            type="number"
            (ngModelChange)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="isAuth ? 8 : 12">
      <nz-form-item>
        <nz-form-label nzNoColon>
          {{ 'AUCTION.COST_CALC.RATE' | translate }}&nbsp;(<a routerLink="/support/delivery">{{
            'DETAILS' | translate
          }}</a
          >)
        </nz-form-label>
        <nz-form-control>
          <nz-input-group nzCompact>
            <nz-select
              [(ngModel)]="rate"
              name="rate"
              [nzPlaceHolder]="'AUCTION.COST_CALC.RATE_PLACEHOLDER' | translate"
              (ngModelChange)="calculate()"
              [style]="rate === wheelsServiceCode ? 'width:50%;' : 'width:100%;'"
              nzDropdownClassName="skiptranslate"
            >
              <nz-option
                *ngFor="let service of allowedServices"
                [nzLabel]="service.title"
                [nzValue]="service.code"
              ></nz-option>
            </nz-select>
            <input
              *ngIf="rate === wheelsServiceCode"
              nz-input
              [(ngModel)]="wheels"
              name="wheels"
              type="number"
              (ngModelChange)="calculate()"
              style="width: 50%"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>

  <div nz-row [nzJustify]="isRussianUser ? 'space-around' : 'start'" class="stats" nzGutter="5">
    <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
      <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
        <nz-descriptions-item [nzTitle]="'AUCTION.COST_CALC.COMPANY_COMMISION' | translate">
          <app-currency
            [value]="comissionCompany"
            [exchangeRate]="exchangeRate"
            layout="vertical"
          ></app-currency>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

    <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
      <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
        <nz-descriptions-item [nzTitle]="'AUCTION.COST_CALC.COAS_IN_JP' | translate">
          <app-currency
            [value]="priceInJapan"
            [exchangeRate]="exchangeRate"
            layout="vertical"
          ></app-currency>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

    <ng-container *ngIf="isRussianUser">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
          <nz-descriptions-item [nzTitle]="'AUCTION.COST_CALC.DELIVERY_TO_VDK' | translate">
            <app-currency
              [value]="deliveryPrice"
              [exchangeRate]="exchangeRate"
              layout="vertical"
            ></app-currency>
          </nz-descriptions-item>
        </nz-descriptions>
      </div>

      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
          <nz-descriptions-item [nzTitle]="'AUCTION.COST_CALC.COAST_IN_VDK' | translate">
            <app-currency
              [value]="totalPrice"
              [exchangeRate]="exchangeRate"
              layout="vertical"
            ></app-currency>
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
    </ng-container>
  </div>
</section>
