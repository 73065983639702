import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SearchServiceEnum {
  Yahoo = 'yahoo',
  YahooCurrent = 'currentYahoo',
  Croober = 'croober',
  Shopping = 'shopping',
  Rakuten = 'rakuten',
  Parts = 'parts',
  Catalog = 'catalog',
  Mercari = 'mercari',
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  currentYahooCategory: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentService: BehaviorSubject<SearchServiceEnum> = new BehaviorSubject<SearchServiceEnum>(null);
  constructor() {}
}
